import axios from "axios";

export default {
  url: "https://api.ecoslider.com",
  findUsers(search = "", pageNumber = 1, pageSize = 12) {
    return axios.get(this.url + "/user", {
      params: {
        search,
        pageNumber,
        pageSize,
      },
    });
  },
  createUser(user) {
    user.roles = [
      user.admin ? "ADMIN" : null,
      user.su ? "SU" : null,
      user.user ? "USER" : null,
    ].filter((n) => n);

    delete user.admin;
    delete user.su;
    delete user.user;

    return axios.post(this.url + "/user", user);
  },
  addRole(userId, role) {
    return axios.post(this.url + `/user/${userId}/${role}`);
  },
  removeRole(userId, role) {
    return axios.delete(this.url + `/user/${userId}/${role}`);
  },
  deleteUser(userId) {
    return axios.delete(this.url + `/user/${userId}`);
  },
};
