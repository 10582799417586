<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="Argon"
      title="Argon"
    >
      <template v-slot:links>
        <sidebar-item
          :link="{
            name: 'Growing plans',
            icon: 'grass',
            path: '/dashboard',
          }"
          v-if="checkRole('ADMIN')"
        />

        <sidebar-item
          :link="{
            name: 'Boxes',
            icon: 'dns',
            path: '/boxes',
          }"
          v-if="checkRole('ADMIN')"
        />
        <sidebar-item
          :link="{
            name: 'Users',
            icon: 'people',
            path: '/users',
          }"
          v-if="checkRole('SU')"
        />
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <!-- your content here -->
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import { store } from "@/store";

export default {
  components: {
    DashboardNavbar,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    checkRole(role) {
      return store.getters.hasRole(role);
    },
  },
};
</script>
<style lang="scss"></style>
