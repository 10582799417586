<template>
  <div
    class="card shadow mb-5"
    style="max-width: 900px"
    :class="type === 'dark' ? 'bg-default' : ''"
  >
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col-4" style="white-space: nowrap">
          <h1 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h1>
        </div>
        <div class="col-8 text-right">
          <base-button
            class="m-1"
            type="danger"
            size=""
            icon="delete_forever"
            @mouseup="$emit('discard')"
            >BACK</base-button
          >
          <base-button
            class="m-1"
            type="primary"
            size=""
            icon="new_label"
            :disabled="requestActive"
            @mouseup="$emit('save', planModel)"
            >{{ planData ? "SAVE" : "CREATE" }} PLAN</base-button
          >
        </div>
      </div>
      <hr class="my-4" />
      <div>
        <form>
          <div class="row">
            <div class="col">
              <base-input
                alternative=""
                label="Name"
                placeholder="Label for growing plan"
                input-classes="form-control-alternative"
                v-model="planModel.name"
              />
            </div>
          </div>
          <hr class="my-4" />
          <!-- Description -->
          <div class="form-group">
            <base-input alternative="" label="Description">
              <textarea
                rows="4"
                class="form-control form-control-alternative"
                placeholder="More information about growing plan ..."
                v-model="planModel.description"
              ></textarea>
            </base-input>
          </div>
          <hr class="my-4" />
          <!-- Box selector -->
          <div class="form-group">
            <label class="form-control-label" for="">Box</label>
            <select
              class="form-control"
              data-toggle="select"
              title="Box select"
              v-model="planModel.boxProfileId"
            >
              <option v-for="box of boxList" :key="box.id" :value="box.id">
                {{ box.name }}
              </option>
            </select>
          </div>

          <div v-if="planData">
            <!-- Attachments -->
            <hr class="my-4" />
            <label class="form-control-label" for="">Attachments</label>
            <div class="row">
              <div
                class="col-6 col-md-4 my-3"
                v-for="file in this.planData.attachments"
                :key="file.id"
              >
                <div class="attachment-item position-relative">
                  <span
                    class="delete material-icons-outlined"
                    @mouseup="$emit('delete-attachment', file.id)"
                    >close</span
                  >
                  <img
                    :src="
                      'https://ecoslider-app-public.s3.eu-north-1.amazonaws.com/' +
                      file.fileKey
                    "
                    class="w-100"
                    style="border-radius: 4px"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <hr class="my-4" />
            <div class="form-group">
              <div
                v-bind="getRootProps()"
                class="w-100 py-5 text-center"
                style="
                  border: 2px dashed #aaa;
                  border-radius: 4px;
                  background-color: #efefef;
                "
              >
                <input v-bind="getInputProps()" />
                <div class="h2" v-if="isDragActive">
                  <div>
                    <span class="material-icons-outlined">upload_file</span>
                  </div>
                  Drop the files here...
                </div>
                <div class="h2" v-else>
                  <div>
                    <span class="material-icons-outlined">file_upload</span>
                  </div>
                  Upload files
                </div>
              </div>
            </div>

            <!-- Plan steps -->
            <hr class="mt-5" />
            <div class="row align-items-center">
              <div class="col">
                <h2 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                  Plan steps
                </h2>
              </div>
              <div class="col text-right">
                <base-button
                  type="success"
                  size=""
                  icon="add"
                  @mouseup="$emit('add-new-step', null)"
                  >NEW STEP</base-button
                >
              </div>
            </div>
            <!-- table -->
            <div class="table-responsive">
              <base-table
                class="table table-flush table-bordered mt-5"
                style="border: 1px solid #e9ecef"
                :class="type === 'dark' ? 'table-dark' : ''"
                :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
                tbody-classes="list"
                :data="planData.steps.slice().sort((a, b) => a.order - b.order)"
              >
                <template v-slot:columns>
                  <th>Step order</th>
                  <th>Description</th>
                  <th>Attachments</th>
                  <th>Actions</th>
                </template>

                <template v-slot:default="row">
                  <!-- Project -->
                  <th scope="row">
                    <div class="media align-items-center">
                      <div class="media-body">
                        <span class="name mb-0 text-sm">{{
                          row.item.order
                        }}</span>
                      </div>
                    </div>
                  </th>
                  <!-- Description -->
                  <td
                    class="description"
                    style="white-space: normal; min-width: 280px"
                  >
                    {{ row.item.description }}
                  </td>

                  <!-- Attachments -->
                  <td>
                    <img
                      :src="
                        'https://ecoslider-app-public.s3.eu-north-1.amazonaws.com/' +
                        row.item.attachments[0]?.fileKey
                      "
                      class="w-100"
                      style="max-width: 200px; max-height: 100px"
                      alt=""
                    />
                  </td>

                  <!-- Actions -->
                  <td class="text-right">
                    <base-button
                      type="primary"
                      icon="edit"
                      :iconOnly="true"
                      :disabled="requestActive"
                      @mouseup="$emit('add-new-step', row.item)"
                      >edit</base-button
                    >
                    <base-button
                      type="danger"
                      icon="delete"
                      :iconOnly="true"
                      :disabled="requestActive"
                      @mouseup="$emit('delete-step', row.item.id)"
                      >delete</base-button
                    >
                  </td>
                </template>
              </base-table>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { useDropzone } from "vue3-dropzone";
import moment from "moment";
import FarmApi from "@/FarmApi";

export default {
  name: "growing-plans",
  props: {
    type: {
      type: String,
    },
    title: String,
    requestActive: Boolean,
    template: Object,
    planData: Object,
  },
  created: function () {
    this.moment = moment;
    if (!this.planData) {
      this.planModel = {
        name: this.template.name,
        description: this.template.description,
        boxProfileId: this.template.boxProfile.id,
      };
    } else {
      this.planModel = Object.assign({}, this.planData);
    }

    FarmApi.findBoxes().then((resp) => {
      this.boxList = resp.data.content;
      if (this.planData?.boxProfile?.id) {
        this.planModel.boxProfileId = this.boxList.find(
          (x) => x.id === this.planData?.boxProfile?.id
        ).id;
      }
    });
  },
  data() {
    return {
      planModel: {},
      boxList: {},
    };
  },
  methods: {},
  setup(props, context) {
    function onDrop(acceptFiles) {
      acceptFiles.forEach((file) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("attachmentType", "IMAGE");
        context.emit("add-plan-attachment", formData);
      });
    }

    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });

    return {
      getRootProps,
      getInputProps,
      ...rest,
    };
  },
};
</script>
<style>
.attachment-item {
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.attachment-item .delete {
  opacity: 0.4;
  color: gray;
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: #d5d5d5;
  border-radius: 4px;
}
.attachment-item .delete:hover {
  opacity: 0.9;
  color: #e41010;
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: #ffb6b6;
  border-radius: 4px;
  cursor: pointer;
}
</style>
