<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h1 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            Users
          </h1>
        </div>
        <div class="col text-right">
          <base-button
            type="success"
            size=""
            icon="add"
            @mouseup="$emit('add-new')"
            >NEW USER</base-button
          >
        </div>
      </div>
    </div>

    <div v-if="users">
      <div class="table-responsive">
        <base-table
          class="table table-flush"
          :class="type === 'dark' ? 'table-dark' : ''"
          :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
          tbody-classes="list"
          :data="users"
        >
          <template v-slot:columns>
            <th>Email</th>
            <th>Password</th>
            <th>Role</th>
            <th>Status</th>
            <th>Updated</th>
            <th>Actions</th>
          </template>

          <template v-slot:default="row">
            <!-- Email -->
            <th scope="row">
              <div class="media align-items-center">
                <div class="media-body">
                  <span class="name mb-0 text-sm">{{ row.item.email }}</span>
                </div>
              </div>
            </th>
            <!-- Password -->
            <td class="description">****************</td>
            <!-- Role -->
            <td>
              <span class="status">{{ row.item.roles.join(", ") }}</span>
            </td>
            <!-- Status -->
            <td>
              <badge :type="getVariantByStatus(row.item.status)">
                <i :class="`bg-${getVariantByStatus(row.item.status)}`"></i>
                <span class="status">{{ row.item.status }}</span>
              </badge>
            </td>
            <!-- Updated date -->
            <td>
              {{ moment(Date.parse(row.item.updated)).format("MMMM DD, YYYY") }}
            </td>

            <td>
              <base-button
                type="success"
                icon="edit"
                :iconOnly="true"
                @mouseup="$emit('add-new', row.item)"
                >edit</base-button
              >
              <base-button
                type="danger"
                icon="delete"
                :iconOnly="true"
                @mouseup="$emit('delete', row.item)"
                >delete</base-button
              >
            </td>
          </template>
        </base-table>
      </div>

      <div
        class="card-footer d-flex justify-content-end"
        :class="type === 'dark' ? 'bg-transparent' : ''"
      >
        <base-pagination
          :perPage="requestData.pageSize"
          :total="requestData.total"
          :value="requestData.pageNumber"
          @input="$emit('select-page', $event)"
        ></base-pagination>
      </div>
    </div>
    <div class="h2 text-center" v-else>
      <hr class="m-0 mb-3" />
      <div class="my-5">
        Loading
        <img src="img/brand/loader.gif" alt="" width="36" class="ml-2" />
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "projects-table",
  props: {
    type: {
      type: String,
    },
    users: Object,
    requestData: Object,
  },
  created: function () {
    this.moment = moment;
  },
  methods: {
    getVariantByStatus(status) {
      switch (status) {
        case "INIT":
          return "primary";
        case "ACTIVE":
          return "success";
        case "BLOCKED":
          return "dark";
        case "DELETED":
          return "danger";
        default:
          return "info";
      }
    },
  },
  data() {
    return {};
  },
};
</script>
<style></style>
