<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row"></div>
    </base-header>

    <div class="container-fluid mt--7">
      <box-overview
        :boxes="boxList"
        @add-new="enableEditorView"
        @delete="deleteBox"
        v-if="!editorView"
      ></box-overview>
      <box-edit
        :title="(editableBox ? 'Edit' : 'Add') + ' box'"
        :box-data="editableBox"
        :template="boxTemplate"
        @discard="exitEditorView"
        @save="saveBox"
        v-else
      ></box-edit>
    </div>
  </div>
</template>
<script>
import BoxOverview from "./cards/BoxOverview";
import BoxEdit from "./cards/BoxEdit";
import FarmApi from "@/FarmApi";

export default {
  components: { BoxOverview, BoxEdit },
  data() {
    return {
      boxList: null,
      boxTemplate: null,
      editorView: false,
      editableBox: null,
    };
  },
  methods: {
    enableEditorView(box) {
      this.editableBox = box;
      this.editorView = true;
    },
    exitEditorView() {
      this.editorView = false;
    },
    deleteBox(box) {
      if (!box) return;
      FarmApi.deleteBox(box.id)
        .then(() => {
          this.boxList = this.boxList.filter((x) => x.id !== box.id);
        })
        .catch((err) => {
          alert(err.response.data.message);
        });
    },
    saveBox(box) {
      if (this.editableBox) {
        FarmApi.updateBox(this.editableBox.id, box)
          .then(() => {
            this.update();
            this.exitEditorView()
          })
          .catch((err) => {
            alert(err.response.data.message);
          });
      } else {
        FarmApi.createBox(box)
          .then(() => {
            this.update();
            this.exitEditorView()
          })
          .catch((err) => {
            alert(err.response.data.message);
          });
      }
    },
    update() {
      FarmApi.findBoxes().then((resp) => {
        this.boxList = resp.data.content;
      });
      FarmApi.findBoxTemplate().then((resp) => {
        this.boxTemplate = resp.data;
      });
    },
  },
  created() {
    this.update();
  },
};
</script>

<style></style>
