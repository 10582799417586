import axios from "axios";

export default {
  url: "https://api.ecoslider.com",
  findPlans(search = "", pageNumber = 1, pageSize = 12) {
    return axios.get(this.url + "/plan/all", {
      params: {
        search,
        pageNumber,
        pageSize,
      },
    });
  },
  findTemplatePlans() {
    return axios.get(this.url + "/plan/template");
  },
  findBoxes() {
    return axios.get(this.url + "/box");
  },
  findBoxTemplate() {
    return axios.get(this.url + "/box/template");
  },
  deleteBox(boxId) {
    return axios.delete(this.url + "/box/" + boxId);
  },
  createBox(box) {
    return axios.post(this.url + "/box", box);
  },
  updateBox(id, box) {
    return axios.put(this.url + "/box/" + id, box);
  },
  createPlan(plan) {
    return axios.post(this.url + "/plan", plan);
  },
  updatePlan(id, plan) {
    return axios.put(this.url + "/plan/" + id, plan);
  },
  updatePlanStatus(id, status) {
    return axios.put(this.url + `/plan/${id}/status/${status}`);
  },
  createStep(planId, step) {
    return axios.post(this.url + "/step/plan/" + planId, step);
  },
  updateStep(step) {
    return axios.put(this.url + "/step/" + step.id, step);
  },
  deleteStep(stepId) {
    return axios.delete(this.url + "/step/" + stepId);
  },
  uploadPlanAttachment(planId, formData){
    return axios.post(this.url + "/attachment/plan/" + planId, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  uploadStepAttachment(planId, formData){
    return axios.post(this.url + "/attachment/step/" + planId, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  deleteAttachment(attachmentId){
    return axios.delete(this.url + "/attachment/" + attachmentId);
  },
};
