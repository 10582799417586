import { createStore } from "vuex";
import { useCookies } from "vue3-cookies";
import VueJwtDecode from "vue-jwt-decode";
import axios from "axios";
const { cookies } = useCookies();

const accessCookie = "token";
const refreshCookie = "refresh";
const emailCookie = "email";

export const store = createStore({
  state() {
    return {
      oauth: {
        authEmail: "",
        token: "",
        refresh: "",
      },
      roles: [],
    };
  },
  getters: {
    hasRole: (state) => (role) => {
      return state.roles.includes(role);
    },
  },
  mutations: {
    login(state, { email, token, refresh }) {
      cookies.set(emailCookie, email);
      cookies.set(accessCookie, token);
      cookies.set(refreshCookie, refresh);

      state.oauth.authEmail = email;
      state.oauth.token = token;
      state.oauth.refresh = refresh;
      state.roles = VueJwtDecode.decode(state.oauth.token).roles;
    },
    loadCookies(state) {
      state.oauth.token = cookies.get(accessCookie);
      axios.defaults.headers.common.Authorization =
        "Bearer " + state.oauth.token;
      if (state.token === null) {
        return;
      }
      state.oauth.refresh = cookies.get(refreshCookie);
      state.oauth.authEmail = cookies.get(emailCookie);
      state.roles = VueJwtDecode.decode(state.oauth.token).roles;
    },
    refreshTokens(state, { token, refresh }) {
      cookies.set(accessCookie, token);
      cookies.set(refreshCookie, refresh);
      state.oauth.token = token;
      state.oauth.refresh = refresh;
      state.roles = VueJwtDecode.decode(state.oauth.token).roles;
    },
    logout(state) {
      cookies.remove(accessCookie);
      cookies.remove(refreshCookie);
      cookies.remove(emailCookie);
      state.oauth.token = null;
      state.oauth.refresh = null;
      state.oauth.authEmail = null;
    },
  },
});
