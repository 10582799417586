<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <base-alert class="mx-5 mt-4 p-2" type="danger" v-if="error"
          >{{ error }}
        </base-alert>
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center h2">Log In to Farm Dashboard</div>
          <div class="text-center text-muted mb-5">
            <small>Enter your email and password bellow</small>
          </div>
          <form role="form" @submit.prevent="handleLogin">
            <label>Email</label>
            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Email"
              addon-left-icon="ni ni-email-83"
              v-model="authModel.email"
            >
            </base-input>

            <label>Password</label>
            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="authModel.password"
            >
            </base-input>

            <base-checkbox class="custom-control-alternative">
              <span class="text-muted">Remember me</span>
            </base-checkbox>
            <div class="text-center">
              <base-button type="primary" class="my-3 w-100" nativeType="submit"
                >Sign in
              </base-button>
            </div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <router-link to="/dashboard" class="text-light">
            <small>Forgot password?</small>
          </router-link>
        </div>
        <div class="col-6 text-right">
          <router-link to="/register" class="text-light"
            ><small>Create new account</small></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AuthApi from "@/AuthApi";
import { store } from "@/store";
import router from "@/router";

export default {
  name: "login",
  data() {
    return {
      error: null,
      authModel: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    handleLogin() {
      if (!this.authModel.email || !this.authModel.password) {
        return;
      }
      AuthApi.login(this.authModel.email, this.authModel.password)
        .then((resp) => {
          store.commit("login", {
            email: this.authModel.email,
            token: resp.data.token,
            refresh: resp.data.refresh,
          });

          if (store.getters.hasRole("ADMIN")) {
            router.push("/dashboard");
          } else if (store.getters.hasRole("SU")) {
            router.push("/users");
          } else {
            alert("You don't have any privileges!");
          }
        })
        .catch((err) => {
          if (err.response) {
            this.error = "Error: " + err.response.data.message;
          } else {
            this.error = "Error: " + JSON.stringify(err);
          }
        });
    },
  },
};
</script>
<style></style>
