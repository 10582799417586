<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row"></div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <growing-plans-step-edit
            title="Step editor"
            :step-data="editableStep"
            :template="template"
            :request-active="requestActive"
            @save="saveStep"
            @add-step-attachment="addStepAttachment"
            @delete-attachment="deleteAttachment"
            @discard="exitStepEditorView"
            v-if="stepEditorView"
          ></growing-plans-step-edit>
          <growing-plans-edit
            title="Plan details"
            :plan-data="editablePlan"
            :template="template"
            :request-active="requestActive"
            @save="savePlan"
            @add-new-step="enableStepEditorView"
            @delete-step="deleteStep"
            @add-plan-attachment="addPlanAttachment"
            @delete-attachment="deleteAttachment"
            @discard="exitEditorView"
            v-else-if="editorView"
          ></growing-plans-edit>
          <growing-plans-overview
            title="Growing plans"
            :plans="growingPlans"
            :request-active="requestActive"
            :request-data="requestData"
            @add-new="enableEditorView"
            @edit-status="editPlanStatus"
            @select-page="setPageNumber"
            v-else
          ></growing-plans-overview>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GrowingPlansOverview from "./cards/GrowingPlansOverview";
import GrowingPlansEdit from "./cards/GrowingPlansEdit";
import GrowingPlansStepEdit from "./cards/GrowingPlansStepEdit";
import FarmApi from "@/FarmApi";

export default {
  name: "tables",
  components: {
    GrowingPlansOverview,
    GrowingPlansEdit,
    GrowingPlansStepEdit,
  },
  data() {
    return {
      template: null,
      requestActive: false,
      editorView: false,
      stepEditorView: false,
      growingPlans: null,
      editablePlan: null,
      editableStep: null,
      requestData: {
        search: "",
        pageNumber: 1,
        total: 0,
        pageSize: 10,
      },
    };
  },
  methods: {
    savePlan(plan) {
      if (!this.validatePlanData(plan)) {
        return;
      }

      if (this.editablePlan) {
        this.requestActive = true;
        FarmApi.updatePlan(this.editablePlan.id, plan)
          .then(() => {
            this.update();
            this.exitEditorView();
          })
          .catch((err) => {
            alert(err.response.data.message);
          })
          .finally(() => {
            this.requestActive = false;
          });
      } else {
        this.requestActive = true;
        FarmApi.createPlan(plan)
          .then(() => {
            this.update();
            this.exitEditorView();
          })
          .catch((err) => {
            alert(err.response.data.message);
          })
          .finally(() => {
            this.requestActive = false;
          });
      }
      this.editorView = true;
    },
    validatePlanData(plan) {
      if (!plan.name) {
        alert("Enter a name for the plan");
        return false;
      }
      if (!plan.description) {
        alert("Please fill in the Description");
        return false;
      }
      if (!plan.boxProfileId) {
        alert("Please select a Box");
        return false;
      }
      return true;
    },

    saveStep(step) {
      if (!this.validateStepData(step)) {
        return;
      }

      if (this.editableStep) {
        this.requestActive = true;
        FarmApi.updateStep(step)
          .then(() => {
            this.update().then(() => {
              this.editablePlan.steps = this.growingPlans.find(
                (x) => x.id === this.editablePlan.id
              ).steps;
            });
            this.exitStepEditorView();
          })
          .catch((err) => {
            alert(err.response.data.message);
          })
          .finally(() => {
            this.requestActive = false;
          });
      } else {
        this.requestActive = true;
        FarmApi.createStep(this.editablePlan.id, step)
          .then(() => {
            this.update().then(() => {
              this.editablePlan.steps = this.growingPlans.find(
                (x) => x.id === this.editablePlan.id
              ).steps;
            });
            this.exitStepEditorView();
          })
          .catch((err) => {
            alert(err.response.data.message);
          })
          .finally(() => {
            this.requestActive = false;
          });
      }
      this.editorView = true;
    },
    validateStepData(step) {
      console.log(step);
      if (!step.name) {
        alert("Enter a name for the step");
        return false;
      }
      if (!step.description) {
        alert("Please fill in the Description");
        return false;
      }
      if (!step.type) {
        alert("Please select a Type");
        return false;
      }
      if (!step.order && typeof step.order !== "number") {
        alert("Please set an order number");
        return false;
      }
      return true;
    },
    deleteStep(stepId) {
      if (!confirm("Are you sure you about this?")) {
        return;
      }
      this.requestActive = true;
      FarmApi.deleteStep(stepId)
        .then(() => {
          this.editablePlan.steps = this.editablePlan.steps.filter(
            (step) => step.id !== stepId
          );
        })
        .catch((err) => {
          alert(err.response.data.message);
        })
        .finally(() => {
          this.requestActive = false;
        });
    },
    addPlanAttachment(formData) {
      this.requestActive = true;
      FarmApi.uploadPlanAttachment(this.editablePlan.id, formData)
        .then((resp) => {
          this.update();
          this.editablePlan.attachments.push(resp.data);
        })
        .catch((err) => {
          alert(err.response.data.message);
        })
        .finally(() => {
          this.requestActive = false;
        });
    },
    addStepAttachment(formData) {
      this.requestActive = true;
      FarmApi.uploadStepAttachment(this.editableStep.id, formData)
        .then((resp) => {
          this.update();
          this.editableStep.attachments.push(resp.data);
        })
        .catch((err) => {
          alert(err.response.data.message);
        })
        .finally(() => {
          this.requestActive = false;
        });
    },
    editPlanStatus({ id, newStatus }) {
      if (newStatus === "DELETED" && !confirm("Are you sure you about this?")) {
        return;
      }
      this.requestActive = true;
      console.log(id);
      console.log(newStatus);
      FarmApi.updatePlanStatus(id, newStatus)
        .then(() => {
          this.growingPlans.find((x) => x.id === id).status = newStatus;
        })
        .finally(() => {
          this.requestActive = false;
        });
    },
    deleteAttachment(id) {
      if (!confirm("Are you sure you about this?")) {
        return;
      }
      this.requestActive = true;
      FarmApi.deleteAttachment(id)
        .then(() => {
          this.editablePlan.attachments = this.editablePlan.attachments.filter(
            (x) => x.id !== id
          );
          this.editableStep.attachments = this.editableStep.attachments.filter(
            (x) => x.id !== id
          );
        })
        .finally(() => {
          this.requestActive = false;
        });
    },
    enableEditorView(plan) {
      this.editablePlan = plan;
      this.editorView = true;
    },
    enableStepEditorView(step) {
      this.editableStep = step;
      this.stepEditorView = true;
    },
    exitEditorView() {
      this.editorView = false;
    },
    exitStepEditorView() {
      this.stepEditorView = false;
    },
    update() {
      FarmApi.findTemplatePlans().then((resp) => {
        this.template = resp.data;
      });

      return FarmApi.findPlans(
        this.requestData.search,
        this.requestData.pageNumber,
        this.requestData.pageSize
      ).then((resp) => {
        this.growingPlans = resp.data.content;
        this.requestData.total = resp.data.total;
      });
    },
    setPageNumber(number) {
      this.requestData.pageNumber = number;
      this.update();
    },
  },
  created() {
    this.update();
  },
};
</script>
<style></style>
