<template>
  <base-nav
    class="navbar-top navbar-dark"
    id="navbar-main"
    :show-toggle-button="false"
    expand
  >
    <div
      class="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-md-auto"
    >
    </div>
    <ul class="navbar-nav align-items-center d-none d-md-flex">
      <li class="nav-item dropdown">
        <base-dropdown class="nav-link pr-0" position="right">
          <template v-slot:title>
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle">
                <img
                  alt="Image placeholder"
                  src="img/theme/avatar_placeholder.png"
                />
              </span>
              <div class="media-body ml-2 d-none d-md-block">
                <span class="mb-0 text-sm font-weight-bold">{{
                  username
                }}</span>
              </div>
            </div>
          </template>
          <div class="dropdown-header noti-title">
            <h6 class="text-overflow m-0">Welcome!</h6>
          </div>
          <router-link to="/login" @click="logout" class="dropdown-item d-inline-flex">
            <div class="material-icons-outlined">logout</div>
            <div class="ml-2">Logout</div>
          </router-link>
        </base-dropdown>
      </li>
    </ul>
  </base-nav>
</template>
<script>
import { store } from "@/store";
import router from "@/router";

export default {
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchQuery: "",
      username: "x",
    };
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    logout() {
      store.commit("logout");
      router.push("/login");
    },
  },
  mounted() {
    if (store.state.oauth.authEmail) {
      this.username = store.state.oauth.authEmail;
    }
  },
};
</script>
