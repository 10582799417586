<template>
  <div
    class="card shadow"
    style="max-width: 600px"
    :class="type === 'dark' ? 'bg-default' : ''"
  >
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col-4" style="white-space: nowrap">
          <h1 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h1>
        </div>
        <div class="col-8 text-right" v-if="!userData">
          <base-button
            class="m-1"
            type="danger"
            size=""
            icon="delete_forever"
            @mouseup="$emit('discard')"
            >BACK</base-button
          >
          <base-button
            class="m-1"
            type="primary"
            size=""
            icon="new_label"
            :disabled="requestActive"
            @mouseup="$emit('save', userModel)"
            >{{ userData ? "SAVE" : "CREATE" }} USER</base-button
          >
        </div>
        <div class="col-8 text-right" v-else>
          <base-button
            class="m-1"
            type="primary"
            size=""
            icon="arrow_back"
            @mouseup="$emit('discard')"
            >BACK</base-button
          >
        </div>
      </div>
      <!-- Main Data -->
      <hr class="my-4" />
      <div>
        <form>
          <div class="row">
            <div class="col">
              <base-input
                alternative=""
                label="Email"
                input-classes="form-control-alternative"
                v-model="userModel.email"
                :disabled="userData"
              />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <base-input
                alternative=""
                label="Password"
                type="password"
                input-classes="form-control-alternative"
                v-model="userModel.password"
                :disabled="userData"
              />
            </div>
          </div>
          <!-- Description -->
          <div v-if="userData">
            <hr class="my-4" />
            <div class="form-group">
              <label class="form-control-label" for="">Roles</label>
              <div>
                <label for="adminCBX" class="mr-2"
                  >Admin
                  <input
                    type="checkbox"
                    id="adminCBX"
                    class="mr-4"
                    value="ADMIN"
                    @mouseup="toggleRole('ADMIN')"
                    v-model="adminComputed"
                /></label>
                <label for="suCBX" class="mr-2"
                  >Superuser
                  <input
                    type="checkbox"
                    id="suCBX"
                    class="mr-4"
                    value="SU"
                    @mouseup="toggleRole('SU')"
                    v-model="suComputed"
                /></label>
                <label for="userCBX" class="mr-2"
                  >User
                  <input
                    type="checkbox"
                    id="userCBX"
                    class="mr-4"
                    value="USER"
                    @mouseup="toggleRole('USER')"
                    v-model="userComputed"
                /></label>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "user-edit",
  props: {
    type: {
      type: String,
    },
    title: String,
    requestActive: Boolean,
    userData: Object,
  },
  created: function () {
    this.moment = moment;
    this.userModel = Object.assign({}, this.userData);
  },
  data() {
    return {
      userModel: {
        roles: [],
      },
      boxList: {},
    };
  },
  computed: {
    adminComputed() {
      if (this.userData) {
        return this.userData.roles.includes("ADMIN");
      }

      return this.userModel.roles.includes("ADMIN");
    },
    suComputed() {
      if (this.userData) {
        return this.userData.roles.includes("SU");
      }

      return this.userModel.roles.includes("SU");
    },
    userComputed() {
      if (this.userData) {
        return this.userData.roles.includes("USER");
      }

      return this.userModel.roles.includes("USER");
    },
  },
  methods: {
    toggleRole(role) {
      if (this.userData) {
        this.$emit("role-update", role);
      } else {
        if (this.userModel.roles.includes(role)) {
          this.userModel.roles = this.userModel.roles.filter((x) => x !== role);
        } else {
          this.userModel.roles.push(role);
        }
      }
    },
  },
};
</script>
<style></style>
