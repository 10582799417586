<template>
  <div
    class="card shadow"
    style="max-width: 900px"
    :class="type === 'dark' ? 'bg-default' : ''"
  >
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col-4" style="white-space: nowrap">
          <h1 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h1>
        </div>
        <div class="col-8 text-right">
          <base-button
            class="m-1"
            type="danger"
            size=""
            icon="delete_forever"
            @mouseup="$emit('discard')"
            >BACK
          </base-button>
          <base-button
            class="m-1"
            type="primary"
            size=""
            icon="new_label"
            @mouseup="$emit('save', boxModel)"
            >SAVE BOX
          </base-button>
        </div>
      </div>
      <hr class="my-4" />
      <div>
        <form>
          <div class="row">
            <div class="col">
              <base-input
                alternative=""
                label="Name"
                input-classes="form-control-alternative"
                v-model="boxModel.name"
              />
            </div>
          </div>
          <!-- Ventilation -->
          <div class="row">
            <div class="col-6">
              <base-input
                alternative=""
                label="Ventilation period ON"
                input-classes="form-control-alternative"
                v-model="boxModel.ventilationPeriodOn"
              />
            </div>
            <div class="col-6">
              <base-input
                alternative=""
                label="Ventilation period OFF"
                input-classes="form-control-alternative"
                v-model="boxModel.ventilationPeriodOff"
              />
            </div>
          </div>
          <!-- Humidity -->
          <div class="row">
            <div class="col-6">
              <base-input
                alternative=""
                label="Humidification min"
                input-classes="form-control-alternative"
                v-model="boxModel.humidificationMin"
              />
            </div>
            <div class="col-6">
              <base-input
                alternative=""
                label="Humidification max"
                input-classes="form-control-alternative"
                v-model="boxModel.humidificationMax"
              />
            </div>
          </div>
          <!-- CO2 -->
          <div class="row">
            <div class="col-6">
              <base-input
                alternative=""
                label="CO2 min"
                input-classes="form-control-alternative"
                v-model="boxModel.co2Min"
              />
            </div>
            <div class="col-6">
              <base-input
                alternative=""
                label="CO2 max"
                input-classes="form-control-alternative"
                v-model="boxModel.co2Max"
              />
            </div>
          </div>
          <!-- Chiller -->
          <div class="row">
            <div class="col-6">
              <base-input
                alternative=""
                label="Chiller min"
                input-classes="form-control-alternative"
                v-model="boxModel.chillerMin"
              />
            </div>
            <div class="col-6">
              <base-input
                alternative=""
                label="Chiller max"
                input-classes="form-control-alternative"
                v-model="boxModel.chillerMax"
              />
            </div>
          </div>
          <!-- Ionizer -->
          <div class="row">
            <div class="col-6">
              <base-input
                alternative=""
                label="Ionizer period ON"
                input-classes="form-control-alternative"
                v-model="boxModel.ionizerPeriodOn"
              />
            </div>
            <div class="col-6">
              <base-input
                alternative=""
                label="Ionizer period OFF"
                input-classes="form-control-alternative"
                v-model="boxModel.ionizerPeriodOff"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "projects-table",
  props: {
    type: {
      type: String,
    },
    title: String,
    template: Object,
    boxData: Object,
  },
  methods: {},
  created: function () {
    this.moment = moment;
    if (!this.boxData) {
      this.boxModel = Object.assign({}, this.template);
    } else {
      this.boxModel = Object.assign({}, this.boxData);
    }
  },
  data() {
    return {
      boxModel: {
        id: null,
        name: null,
        ventilationPeriodOn: null,
        ventilationPeriodOff: null,
        humidificationMin: null,
        humidificationMax: null,
        co2Min: null,
        co2Max: null,
        chillerMin: null,
        chillerMax: null,
        ionizerPeriodOn: null,
        ionizerPeriodOff: null,
        created: null,
        updated: null,
      },
    };
  },
};
</script>
<style></style>
