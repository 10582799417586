<template>
  <div>
    <div v-if="boxes">
      <div class="row">
        <div
          class="col-12 col-xl-6 mb-4"
          style="max-width: 500px"
          v-for="box of boxes"
          v-bind:key="box.id"
        >
          <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
            <div
              class="card-header border-0"
              :class="type === 'dark' ? 'bg-transparent' : ''"
            >
              <div class="row align-items-center">
                <div class="col">
                  <h1 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                    {{ box.name }}
                  </h1>
                </div>
                <div class="col text-right">
                  <base-button
                      type="success"
                      icon="edit"
                      :iconOnly="true"
                      @mouseup="$emit('add-new', box)"
                  >edit</base-button
                  >
                  <base-button
                      type="danger"
                      icon="delete"
                      :iconOnly="true"
                      @mouseup="$emit('delete', box)"
                  >delete</base-button
                  >
                </div>
              </div>
            </div>

            <hr class="m-0 mb-3" />
            <div class="mx-4 mb-3 d-inline-flex">
              <div class="font-weight-bold">Ventilation period ON</div>
              <div class="ml-auto">{{ box.ventilationPeriodOn }}</div>
            </div>
            <div class="mx-4 mb-3 d-inline-flex">
              <div class="font-weight-bold">Ventilation period OFF</div>
              <div class="ml-auto">{{ box.ventilationPeriodOff }}</div>
            </div>
            <hr class="m-0 mb-3" />
            <div class="mx-4 mb-3 d-inline-flex">
              <div class="font-weight-bold">Humidification min</div>
              <div class="ml-auto">{{ box.humidificationMin }}%</div>
            </div>
            <div class="mx-4 mb-3 d-inline-flex">
              <div class="font-weight-bold">Humidification max</div>
              <div class="ml-auto">{{ box.humidificationMax }}%</div>
            </div>
            <hr class="m-0 mb-3" />
            <div class="mx-4 mb-3 d-inline-flex">
              <div class="font-weight-bold">CO2 min</div>
              <div class="ml-auto">{{ box.co2Min }}</div>
            </div>
            <div class="mx-4 mb-3 d-inline-flex">
              <div class="font-weight-bold">CO2 max</div>
              <div class="ml-auto">{{ box.co2Max }}</div>
            </div>
            <hr class="m-0 mb-3" />
            <div class="mx-4 mb-3 d-inline-flex">
              <div class="font-weight-bold">Chiller min</div>
              <div class="ml-auto">{{ box.chillerMin }}</div>
            </div>
            <div class="mx-4 mb-3 d-inline-flex">
              <div class="font-weight-bold">Chiller max</div>
              <div class="ml-auto">{{ box.chillerMax }}</div>
            </div>
            <hr class="m-0 mb-3" />
            <div class="mx-4 mb-3 d-inline-flex">
              <div class="font-weight-bold">Ionizer period ON</div>
              <div class="ml-auto">{{ box.ionizerPeriodOn }} sec</div>
            </div>
            <div class="mx-4 mb-3 d-inline-flex">
              <div class="font-weight-bold">Ionizer period OFF</div>
              <div class="ml-auto">{{ box.ionizerPeriodOff }} sec</div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-12" style="max-width: 400px">
          <base-button
            type="success"
            size=""
            icon="add"
            @mouseup="$emit('add-new', null)"
            >NEW BOX</base-button
          >
        </div>
      </div>
    </div>
    <div class="h2 text-center card shadow mx-5 mt--6" v-else>
      <hr class="m-0 mb-3" />
      <div class="my-5">
        Loading {{ boxes }}
        <img src="img/brand/loader.gif" alt="" width="36" class="ml-2" />
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "projects-table",
  props: {
    type: {
      type: String,
    },
    boxes: Object,
  },
  created: function () {
    this.moment = moment;
  },
  data() {
    return {};
  },
};
</script>
<style></style>
