<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h1 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h1>
        </div>
        <div class="col text-right">
          <base-button
            type="success"
            size=""
            icon="add"
            :disabled="requestActive"
            @mouseup="$emit('add-new')"
            >NEW PLAN</base-button
          >
        </div>
      </div>
    </div>

    <div v-if="plans">
      <div class="table-responsive">
        <base-table
          class="table table-flush"
          :class="type === 'dark' ? 'table-dark' : ''"
          :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
          tbody-classes="list"
          :data="plans"
        >
          <template v-slot:columns>
            <th>Project</th>
            <th>description</th>
            <th>Status</th>
            <th>Created</th>
            <th>Updated</th>
            <th>Attachments</th>
            <th>Actions</th>
          </template>

          <template v-slot:default="row">
            <!-- Project -->
            <th scope="row">
              <div class="media align-items-center">
                <div class="media-body">
                  <span class="name mb-0 text-sm">{{ row.item.name }}</span>
                </div>
              </div>
            </th>
            <!-- Description -->
            <td
              class="description p-2"
              style="white-space: pre-line; min-width: 280px"
            >
              <div class="description-container">
                {{ row.item.description }}
              </div>
            </td>
            <!-- Status -->
            <td>
              <badge :type="getVariantByStatus(row.item.status)">
                <i :class="`bg-${getVariantByStatus(row.item.status)}`"></i>
                <span class="status">{{ row.item.status }}</span>
              </badge>
            </td>
            <!-- Created date -->
            <td>
              {{ moment(Date.parse(row.item.created)).format("MMMM DD, YYYY") }}
            </td>
            <!-- Updated date -->
            <td>
              {{ moment(Date.parse(row.item.updated)).format("MMMM DD, YYYY") }}
            </td>

            <!-- Attachments -->
            <td>
              <img
                :src="
                  'https://ecoslider-app-public.s3.eu-north-1.amazonaws.com/' +
                  row.item.attachments[0]?.fileKey
                "
                class="w-100"
                alt=""
              />
            </td>

            <!-- Actions -->
            <td>
              <base-button
                type="primary"
                icon="edit"
                :iconOnly="true"
                @mouseup="$emit('add-new', row.item)"
                >add</base-button
              >
              <base-button
                v-if="['INIT', 'ACTIVE', 'DELETED'].includes(row.item.status)"
                :icon="row.item.status === 'ACTIVE' ? 'delete' : 'play_circle'"
                :type="row.item.status === 'ACTIVE' ? 'danger' : 'success'"
                :iconOnly="true"
                @mouseup="
                  $emit('edit-status', {
                    id: row.item.id,
                    newStatus:
                      row.item.status === 'ACTIVE' ? 'DELETED' : 'ACTIVE',
                  })
                "
                >toggle</base-button
              >
            </td>
          </template>
        </base-table>
      </div>

      <div
        class="card-footer d-flex justify-content-end"
        :class="type === 'dark' ? 'bg-transparent' : ''"
      >
        <base-pagination
          :perPage="requestData.pageSize"
          :total="requestData.total"
          :value="requestData.pageNumber"
          @input="$emit('select-page', $event)"
        ></base-pagination>
      </div>
    </div>
    <div class="h2 text-center" v-else>
      <hr class="m-0 mb-3" />
      <div class="my-5">
        Loading
        <img src="img/brand/loader.gif" alt="" width="36" class="ml-2" />
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "projects-table",
  props: {
    type: {
      type: String,
    },
    title: String,
    requestActive: Boolean,
    plans: Object,
    requestData: Object,
  },
  created: function () {
    this.moment = moment;
  },
  data() {
    return {};
  },
  methods: {
    getVariantByStatus(status) {
      switch (status) {
        case "INIT":
          return "primary";
        case "ACTIVE":
          return "success";
        case "HIDDEN":
          return "dark";
        case "TEMPLATE":
          return "info";
        case "DELETED":
          return "danger";
        default:
          return "info";
      }
    },
  },
};
</script>
<style>
.description-container {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
