/*!

=========================================================
* Vue Argon Dashboard - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/vue-argon-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/vue-argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { createApp } from "vue";
import { store } from "./store";
import App from "./App.vue";
import router from "./router";
import ArgonDashboard from "./plugins/argon-dashboard";

import axios from "axios";
import Auth from "@/AuthApi";
import createAuthRefreshInterceptor from "axios-auth-refresh";

import "element-plus/lib/theme-chalk/index.css";

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.mount("#app");

const requestToken = axios.create();
requestToken.interceptors.response.use(
  (res) => res,
  (err) => {
    router.push("/login");
    store.commit("logout");
    return err;
  }
);

var refreshAuthLogic = (failedRequest) =>
  requestToken
    .get(Auth.url + "/refresh", {
      headers: {
        Authorization: "Bearer " + store.state.oauth.refresh,
      },
    })
    .then((resp) => {
      console.log(resp);
      store.commit("refreshTokens", {
        token: resp.data.token,
        refresh: resp.data.refresh,
      });
      failedRequest.response.config.headers.Authorization =
        "Bearer " + resp.data.token;
      return Promise.resolve();
    });

createAuthRefreshInterceptor(axios, refreshAuthLogic);

store.watch(
  (state) => {
    return state.oauth;
  },
  (newValue) => {
    axios.defaults.headers.common.Authorization = "Bearer " + newValue.token;
  },
  {
    deep: true,
  }
);

store.commit("loadCookies");
