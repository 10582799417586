<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row"></div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <users-edit
            :title="(editableUser ? 'Edit' : 'Add') + ' user'"
            :user-data="editableUser"
            @discard="exitEditorView"
            @save="saveUser"
            @role-update="toggleRole"
            v-if="editorView"
          ></users-edit>
          <users-overview
            :users="userList"
            :request-data="requestData"
            @add-new="enableEditorView"
            @delete="deleteUser"
            @select-page="setPageNumber"
            v-else
          ></users-overview>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UsersOverview from "./cards/UsersOverview";
import UsersEdit from "./cards/UsersEdit";
import UserApi from "@/UserApi";

export default {
  name: "users",
  components: { UsersOverview, UsersEdit },
  data() {
    return {
      userList: null,
      editorView: false,
      editableUser: null,
      requestData: {
        search: "",
        pageNumber: 1,
        total: 0,
        pageSize: 10,
      },
    };
  },
  methods: {
    toggleRole(role) {
      if (this.editableUser.roles.includes(role)) {
        UserApi.removeRole(this.editableUser.id, role).then(() => {
          this.editableUser.roles = this.editableUser.roles.filter(
            (x) => x !== role
          );
        });
      } else {
        UserApi.addRole(this.editableUser.id, role).then(() => {
          this.editableUser.roles.push(role);
        });
      }
    },
    enableEditorView(user) {
      this.editableUser = user;
      this.editorView = true;
    },
    exitEditorView() {
      this.editorView = false;
    },
    deleteUser(user) {
      if (!confirm("Are you sure you about this?")) {
        return;
      }
      if (!user) return;
      UserApi.deleteUser(user.id)
        .then(() => {
          this.userList = this.userList.filter((x) => x.id !== user.id);
        })
        .catch((err) => {
          alert(err.response.data.message);
        });
    },
    saveUser(user) {
      if (!this.editableUser) {
        UserApi.createUser(user)
          .then(() => {
            this.update();
            this.exitEditorView();
          })
          .catch((err) => {
            alert(err.response.data.message);
          });
      } else {
        this.update();
        this.exitEditorView();
      }
    },
    update() {
      UserApi.findUsers(
        this.requestData.search,
        this.requestData.pageNumber,
        this.requestData.pageSize
      ).then((resp) => {
        this.userList = resp.data.content;
        this.requestData.total = resp.data.total;
      });
    },
    setPageNumber(number) {
      this.requestData.pageNumber = number;
      this.update();
    },
  },
  created() {
    this.update();
  },
};
</script>
<style></style>
