import axios from "axios";

export default {
  url: "https://api.ecoslider.com",
  login(email, password) {
    return axios.post(
      this.url + "/login",
      { email, password },
      {
        headers: {
          Accept: "application/json",
        },
      }
    );
  },
  resetPassword(email) {
    return axios.get(this.url + "/user/reset?email=" + email);
  }
};
