<template>
  <div
    class="card shadow"
    style="max-width: 800px"
    :class="type === 'dark' ? 'bg-default' : ''"
  >
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col-4" style="white-space: nowrap">
          <h1 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h1>
        </div>
        <div class="col-8 text-right">
          <base-button
            class="m-1"
            type="danger"
            size=""
            icon="delete_forever"
            @mouseup="$emit('discard')"
            >BACK</base-button
          >
          <base-button
            class="m-1"
            type="primary"
            size=""
            icon="new_label"
            :disabled="requestActive"
            @mouseup="$emit('save', stepModel)"
            >{{ stepData ? "SAVE" : "CREATE" }} STEP</base-button
          >
        </div>
      </div>
      <hr class="my-4" />
      <div>
        <form>
          <div class="row">
            <div class="col-4">
              <base-input
                alternative=""
                label="Name"
                placeholder="Label for step"
                input-classes="form-control-alternative"
                v-model="stepModel.name"
              />
            </div>
            <div class="col-4">
              <label class="form-control-label" for="">Type</label>
              <select
                class="form-control"
                data-toggle="select"
                title="Box select"
                v-model="stepModel.type"
              >
                <option
                  v-for="type of stepTypes"
                  :key="type.id"
                  :value="type.id"
                  :selected="type.default === true"
                >
                  {{ type.name }}
                </option>
              </select>
            </div>
            <div class="col-4">
              <base-input
                alternative=""
                label="Order"
                placeholder="Number of step order"
                input-classes="form-control-alternative"
                v-model="stepModel.order"
              />
            </div>
          </div>
          <!-- Description -->
          <hr class="my-4" />
          <div class="form-group mb-3">
            <base-input alternative="" label="Step Description">
              <textarea
                rows="4"
                class="form-control form-control-alternative"
                placeholder="More information about step ..."
                v-model="stepModel.description"
              ></textarea>
            </base-input>
          </div>

          <!-- Period -->
          <div v-if="periodDisplay">
            <hr class="my-4" />
            <div class="row">
              <div class="col-6 col-md-4">
                <base-input
                  alternative=""
                  label="Period"
                  placeholder="Enter time, e.g. 00:12:30"
                  input-classes="form-control-alternative"
                  v-model="stepModel.period"
                />
              </div>
            </div>
          </div>

          <!-- Automation properties -->
          <div v-if="autoPropertiesDisplay">
            <hr class="my-4" />
            <div class="row">
              <div class="col-6">
                <base-input
                  alternative=""
                  label="Air Period ON"
                  placeholder="Enter time, e.g. 00:12:30"
                  input-classes="form-control-alternative"
                  v-model.number="stepModel.airPeriodOn"
                />
              </div>
              <div class="col-6">
                <base-input
                  alternative=""
                  label="Air Period OFF"
                  placeholder="Enter time, e.g. 00:12:30"
                  input-classes="form-control-alternative"
                  v-model.number="stepModel.airPeriodOff"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <base-input
                  alternative=""
                  label="Light Time ON"
                  placeholder="Enter time, e.g. 00:12:30"
                  input-classes="form-control-alternative"
                  v-model="stepModel.lightTimeOn"
                />
              </div>
              <div class="col-6">
                <base-input
                  alternative=""
                  label="Light Time OFF"
                  placeholder="Enter time, e.g. 00:12:30"
                  input-classes="form-control-alternative"
                  v-model="stepModel.lightTimeOff"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <base-input
                  alternative=""
                  label="Watering Period ON"
                  placeholder="Enter time, e.g. 00:12:30"
                  input-classes="form-control-alternative"
                  v-model.number="stepModel.wateringPeriodOn"
                />
              </div>
              <div class="col-6">
                <base-input
                  alternative=""
                  label="Watering Period OFF"
                  placeholder="Enter time, e.g. 00:12:30"
                  input-classes="form-control-alternative"
                  v-model.number="stepModel.wateringPeriodOff"
                />
              </div>
            </div>
          </div>

          <!-- Attachments -->
          <div class="form-group mb-3" v-if="stepData">
            <hr class="my-4" />
            <label class="form-control-label" for="">Attachments</label>
            <div class="row">
              <div
                class="col-6 col-md-4 my-3"
                v-for="file in this.stepData.attachments"
                :key="file.id"
              >
                <div class="attachment-item position-relative">
                  <span
                    class="delete material-icons-outlined"
                    @mouseup="$emit('delete-attachment', file.id)"
                    >close</span
                  >
                  <img
                    :src="
                      'https://ecoslider-app-public.s3.eu-north-1.amazonaws.com/' +
                      file.fileKey
                    "
                    class="w-100"
                    style="border-radius: 4px"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <hr class="my-4" />
            <div class="form-group">
              <div
                v-bind="getRootProps()"
                class="w-100 py-5 text-center"
                style="
                  border: 2px dashed #aaa;
                  border-radius: 4px;
                  background-color: #efefef;
                "
              >
                <input v-bind="getInputProps()" />
                <div class="h2" v-if="isDragActive">
                  <div>
                    <span class="material-icons-outlined">upload_file</span>
                  </div>
                  Drop the files here...
                </div>
                <div class="h2" v-else>
                  <div>
                    <span class="material-icons-outlined">file_upload</span>
                  </div>
                  Upload files
                </div>
              </div>
            </div>
          </div>
          <hr class="my-4" />
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { useDropzone } from "vue3-dropzone";

export default {
  name: "growing-plan-step",
  props: {
    type: {
      type: String,
    },
    title: String,
    requestActive: Boolean,
    template: Object,
    stepData: Object,
  },
  created: function () {
    this.moment = moment;

    if (!this.stepData) {
      this.initModel();
      this.stepModel = Object.assign({},this.template.steps[0]);
    } else {
      this.stepModel = Object.assign({}, this.stepData);
    }
  },
  methods: {
    initModel() {
      this.stepModel.period = "00:00:00";
      this.stepModel.airPeriodOn = "00:00:00";
      this.stepModel.airPeriodOff = "00:00:00";
      this.stepModel.lightTimeOn = "00:00:00";
      this.stepModel.lightTimeOff = "00:00:00";
      this.stepModel.wateringPeriodOn = "00:00:00";
      this.stepModel.wateringPeriodOff = "00:00:00";
    },
  },
  data() {
    return {
      stepTypes: [
        { id: "FULLY_MANUAL", name: "Fully manual", default: true },
        { id: "FULLY_AUTO", name: "Fully auto" },
        { id: "AUTO_MANUAL_END", name: "Auto manual end" },
        { id: "INFINITE", name: "Infinite" },
      ],
      stepModel: {},
      boxList: {},
    };
  },
  computed: {
    autoPropertiesDisplay() {
      return this.stepModel.type !== "FULLY_MANUAL";
    },
    periodDisplay() {
      return this.stepModel.type !== "INFINITE";
    },
  },
  setup(props, context) {
    function onDrop(acceptFiles) {
      acceptFiles.forEach((file) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("attachmentType", "IMAGE");
        context.emit("add-step-attachment", formData);
      });
    }

    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });

    return {
      getRootProps,
      getInputProps,
      ...rest,
    };
  },
};
</script>
<style></style>
